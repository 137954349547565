import { template as template_dd4d8835fafe40868a9a255a84baa4e6 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_dd4d8835fafe40868a9a255a84baa4e6(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
