import { template as template_987ebff4d2b14719a6c5482fcee1e02e } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_987ebff4d2b14719a6c5482fcee1e02e(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
