import { template as template_1255277d685c4f339fa582b8aea3293e } from "@ember/template-compiler";
const FKControlMenuContainer = template_1255277d685c4f339fa582b8aea3293e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
